<template>
    <div>
        <div class="grid grid-4 gap-2 py-2">
          <div v-if="wiseAccountDetails?.accountRequirementsData && Object.keys(this.filters).length != 0 " class="grid grid-4 gap-2 py-2">
             <Superselect
                title='Recieve Currency'
                v-model="currency"
                track-by="code"
                label="code"
                :multiple="false"
                :options="wiseCurrencies || []"
                :loading="$apollo.queries.wiseCurrencies.loading"
                @select="refetchAccountRequirements"
              >
            </Superselect>
            <div v-if="currency">
              <Superselect
                  title='Pay Option'
                  v-model="payOption"
                  track-by="key"
                  label="title"
                  :multiple="false"
                  :options="this.payOptions || []"
                  @select="refetchPayOption"
                  :loading="$apollo.queries.wiseAccountDetails.loading"
                >
              </Superselect>
            </div>
            <div v-if="payOption">
              <YInput v-model="filters.name.fullName" label="Account Holder Full Name"></YInput>
              <div v-if="payOption.key == 'Email' || payOption.key == 'email'">
                <YInput v-model="filters.aDetails['email']" label="Prefered Email"></YInput>
              </div>
              <div v-for="(value, key) in getAccountRequirements" :key="key">
                <div v-if="value.fieldType == 'input' || value.fieldType == 'text'">
                  <YInput v-model="filters.aDetails[value?.key]" :label=value?.name></YInput>
                </div>
                <div v-if="value.fieldType == 'select'">
                  <Superselect
                      :title=value?.name
                      v-model="filters.aDetails[value?.key]"
                      track-by="key"
                      label="name"
                      :multiple="false"
                      :options="value?.valuesAllowed || []"
                      :loading="$apollo.queries.wiseAccountDetails.loading"
                    >
                  </Superselect>
                </div>  
              </div>
            </div> 
          </div>
          <div>
            <YButton
              class="btn btn-blue"
              :is-loading="isSubmitting"
              @click="saveWiseDetails"
              @keydown.enter="saveWiseDetails"
              >Save</YButton
            >
          </div>
        </div>
    </div>
  </template>


<script>
import UPDATE_CREATE_WISE_ACCOUNT from '@/graphql/Wise/WiseUpdateCreateAccount.gql'
import WISE_GET_CURRENCIES from '@/graphql/Wise/WiseGetCurrencies.gql'
import WISE_ACCOUNT_DETAILS from '@/graphql/Wise/WiseGetAccountDetails.gql'

export default {

  apollo: {
    wiseCurrencies:{
      query: WISE_GET_CURRENCIES,
      update: data => data.wiseGetCurrencies,
      fetchPolicy: 'network-only',
      deep: true   
    },
    wiseAccountDetails:{
      query: WISE_ACCOUNT_DETAILS,
      update: data => data.wiseGetAccountDetails,
      variables() {
        return {
          input:{
            //accountId: this.user.userDetails?.wiseAccountId ?? 838372526,
            wiseAccountId: this.user.userDetails?.wiseAccountId ?? undefined,
            userId: this.user?.id  ?? undefined,
            fullName: this.filters?.name?.fullName  ?? undefined,
            paymentType: this.payOption?.key  ?? undefined,
            details: this.filters?.aDetails  ?? undefined,
            currencyCode: this.currency?.code,
            qouteId: this.quouteId ?? undefined,
          }
        }
       },
      result(results) {            
        if (this.optionChange == false){
            this.createFilters(JSON.parse(JSON.stringify(results.data.wiseGetAccountDetails)))
         }
        this.payOptions = this.getPayOptions()
      },
      fetchPolicy: 'network-only',
      deep: true
    },
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      payOption: null,
      filters: {
        name:{},
        aDetails:{}
      },
      computedAccountRequirements: null,
      payOptions:null,
      currentTitle: null,
      isVisible: true,
      isSubmitting: false,
      resetDetails: false,
      optionChange: false,
      currency: null,
      qouteId: null,
      queries: {
        WISE_ACCOUNT_DETAILS,
        WISE_GET_CURRENCIES,
      },
    }
  },
  methods: {
    refetchPayOption(){
      this.filters.aDetails = {}
      this.resetDetails = true
      this.optionChange = true
    },
    refetchAccountRequirements(){
      this.filters.aDetails = {}
      this.payOption = null
      this.resetDetails = true
      this.optionChange = true
    },  
    getPayOptions(){
      var types = []
      this.wiseAccountDetails?.accountRequirementsData.forEach(element => {
        types.push({
          title:  element.title,
          key: element.key
        })
      });
      return types
    },
    createFilters(data){
      for(const key in data.recipientAccount ){
         if(key === '__typename' || key === 'displayFields'){
           continue
         }
         var value = data.recipientAccount[key]
         if (key == 'type'){
          this.payOption = value
          continue
         }
         if (key == 'currency' && this.resetDetails == true){
          continue
         }
         if (key == 'currency' && this.resetDetails == false){
            this.currency = value
            continue
         }
         
        
         if(typeof value === 'object' && value !== null){
            var innerObject = {}
            for(const vKey in value){
              if(vKey === '__typename'){
                continue
              }
              //the frorm generated from getRequirments has address.city, for some dumb reason, so we eigher adjust the form or adjust the filters.
              if (key == 'address'){
                innerObject['address.'+vKey] = value[vKey]
                if (vKey == 'state' || vKey == 'country'){
                  innerObject['address.'+vKey] ={'key':value[vKey],'name':value[vKey]}
                }
              } else {
                innerObject[vKey] = value[vKey]
              }
              
            }
            //if its just details the filters object will never update correctly?!?!?why I don't know javascript or vue jank?
            if (key == 'details'){
              this.filters['aDetails'] = innerObject
            }
            else {
              this.filters[key] = innerObject
            }
         } else {
          this.filters[key] = value
         }    
      }
      if (this.filters?.address !== undefined){
        var merged = {...this.filters.address, ... this.filters.aDetails};
        this.filters.aDetails = merged
      }

      if(this.resetDetails == true){
       this.payOption = null
       this.filters.aDetails = {}
       this.resetDetails = false
      }
    },
    visibilityChanged(isVisible) {
      this.isVisible = isVisible
    },
    
    saveWiseDetails() {
      var valid = true

      if (this.filters?.name?.fullName === undefined){
        this.$toastr.e('Full Name required')
        valid = false
      }
      if (this.payOption?.key === undefined){
        this.$toastr.e('Payment Type required')
        valid = false
      }
      if ( this.currency?.code === undefined){
        this.$toastr.e('Currency required')
        valid = false
      }
      
      if (valid == true){
        this.isUpdating = true
        this.$store.dispatch('updateGlobalLoader', 1)
        this.$apollo
          .mutate({
            // Query
            mutation: UPDATE_CREATE_WISE_ACCOUNT,
            // Parameters
            variables: {
              input: {
                wiseAccountId: this.user.userDetails?.wiseAccountId ?? undefined,
                userId: this.user.id,
                fullName: this.filters.name.fullName,
                paymentType: this.payOption.key,
                details: this.filters.aDetails,
                currencyCode: this.currency.code,
              }
            }
          })
          .then((result) => {
            var errors  = result.data.wiseUpdateCreateRecipientAccount.errors
            if (errors !== null){
              this.$toastr.e(errors)
            } else {
              this.user.userDetails.wiseAccountId = result.data.wiseUpdateCreateRecipientAccount.status
              this.$toastr.s('Success, Wise reciepient account updated/created')
            }
            this.isUpdating = false
            this.$store.dispatch('updateGlobalLoader', -1)
            
          })
          .catch((error) => {
            // Error
            this.isUpdating = false
            this.$store.dispatch('updateGlobalLoader', -1)
            this.$toastr.e(error)
          })
      }
    }
  },
  computed: {
    getAccountRequirements(){
      if(this.wiseAccountDetails?.accountRequirementsData){
        var filtered = this.wiseAccountDetails?.accountRequirementsData.filter((element) => element.key == this.payOption.key)[0]
        return filtered?.requirementsData ?? []
      }
      return []
      
    }
  }
}
</script>

<style scoped></style>
